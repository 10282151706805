if ("NodeList" in window && !NodeList.prototype.forEach) {
  console.info("polyfill for IE11");

  NodeList.prototype.forEach = function (callback, thisArg) {
    thisArg = thisArg || window;

    for (let i = 0; i < this.length; i++) {
      callback.call(thisArg, this[i], i, this);
    }
  };
}

function loadPosts(cat_name, posts, tag, page, more = false) {
  var data_posts = posts != null ? posts : null;
  var data_cat_name = cat_name != null ? cat_name : null;
  var data_tag = tag != null ? tag : null;
  var data_page = page != null ? page : null;
  jQuery.ajax({
    url: kasa_vars.ajaxurl,
    type: "post",
    data: {
      action: "kasa_ajax_getPosts",
      posts: data_posts,
      cat_name: data_cat_name,
      tag: data_tag,
      page: data_page
    },
    beforeSend: function () {
      console.log("loading");

      if (more == false) {
        jQuery("#posts-container").empty();
      }

      jQuery(".posts__loading").show();
    },
    success: function (data) {
      console.log("success");

      if (more == false) {
        jQuery("#posts-container").html(data);
      } else {
        jQuery(".posts__more").remove();
        jQuery("#posts-container").append(data);
      }

      jQuery(".posts__loading").hide();
    },
    error: function () {
      jQuery(".posts__loading").html("There was an error loading the posts");
    }
  });
}

(function ($) {
  loadPosts();
  const btn = document.querySelectorAll(".btn, #header li.featured a");

  if (btn) {
    console.log("btn");

    for (let b = 0; b < btn.length; b++) {
      const this_html = btn[b].innerHTML;
      btn[b].dataset.title = this_html;
    }
  }

  $(".testimonials__slider").slick({
    arrows: false,
    dots: true
  });
  $(".gallery-slider").slick({
    dots: true,
    fade: true,
    responsive: [{
      breakpoint: 600,
      settings: {
        arrows: false
      }
    }]
  });
  $(".home-features__slider").slick({});
  $(".text-image-slider__carousel").slick({
    fade: true
  });
  const tabs_links = document.querySelectorAll(".tabs-quad__nav a");

  if (tabs_links) {
    for (let i = 0; i < tabs_links.length; i++) {
      tabs_links[i].addEventListener("click", function (event) {
        event.preventDefault();
        const thisHref = this.getAttribute("href");
        const tabItems = document.getElementsByClassName("tabs-quad__item");

        for (let m = 0; m < tabItems.length; m++) {
          tabItems[m].classList.remove("active");
        }

        document.getElementById(thisHref).classList.add("active");

        for (let m = 0; m < tabs_links.length; m++) {
          tabs_links[m].classList.remove("active");
        }

        this.classList.add("active");
      });
    }
  }

  $(".fancybox").fancybox({
    padding: 0
  });
  const open_contact = document.querySelectorAll(".open-contact");

  if (open_contact) {
    for (i = 0; i < open_contact.length; i++) {
      open_contact[i].addEventListener("click", function (event) {
        event.preventDefault();
        document.querySelector(".header-contact").classList.toggle("active");
      });
    }
  }

  const fixed_nav_contact = document.querySelector(".fixed-nav__contact");

  if (fixed_nav_contact) {
    fixed_nav_contact.addEventListener("click", function (event) {
      event.preventDefault();
      document.querySelector(".header-contact").classList.add("active");
    });
  }

  const fixed_nav_menu = document.querySelector(".fixed-nav__menu");

  if (fixed_nav_menu) {
    fixed_nav_menu.addEventListener("click", function (event) {
      event.preventDefault();
      document.querySelector(".header__right").classList.add("open");
    });
  }

  const header_close_nav = document.querySelector(".header__close-nav");

  if (header_close_nav) {
    header_close_nav.addEventListener("click", function (event) {
      event.preventDefault();
      document.querySelector(".header__right").classList.remove("open");
    });
  }

  const close_contact = document.querySelector(".header-contact__close");

  if (close_contact) {
    close_contact.addEventListener("click", function (event) {
      event.preventDefault();
      document.querySelector(".header-contact").classList.remove("active");
    });
  }

  const header_lang = document.querySelector(".header__lang span");

  if (header_lang) {
    header_lang.addEventListener("click", function () {
      const win_width = window.innerWidth;

      if (win_width <= 1200) {
        document.querySelector(".lang-dropdown").classList.toggle("open");
      }
    });
  }

  var videoUrl;
  $(".open-video").click(function (event) {
    event.preventDefault();
    videoUrl = $(this).attr("data-video");
  });
  $(".open-video").fancybox({
    afterShow: function () {
      console.log(videoUrl);
      $("#lightbox-video").attr("src", videoUrl);
      $("#lightbox-video")[0].play();
    },
    overlayShow: true,
    centerOnScroll: true,
    speedIn: 100,
    speedOut: 50,
    allowfullscreen: true,
    width: "100%",
    height: "100%",
    autosize: false
  });

  function scrollDown() {
    var win_width = window.innerWidth;
    var top = window.scrollY; //Header animation

    const headerEl = document.getElementById("header");

    if (win_width > 600) {
      if (top > 80) {
        headerEl.classList.add("scroll"); //headerLogo.setAttribute('src',logoData);
      } else {
        headerEl.classList.remove("scroll"); //headerLogo.setAttribute('src',logoSrc);
      }
    } //Scroll animations


    var animatedEl = document.getElementsByClassName("animate-fade");
    var h = window.innerHeight;
    var i;

    for (i = 0; i < animatedEl.length; i++) {
      var viewportOffset = animatedEl[i].getBoundingClientRect();
      var topPos = viewportOffset.top;

      if (topPos <= h * 0.8) {
        animatedEl[i].classList.add("fade-in");
      }
    } // Lazy Load


    var lazy_image = document.querySelectorAll("[data-src]");

    if (lazy_image) {
      //console.log(lazy_image);
      for (var l = 0; l < lazy_image.length; l++) {
        var data_src = lazy_image[l].dataset.src;
        var viewportOffset = lazy_image[l].getBoundingClientRect();
        var topPos = viewportOffset.top;

        if (topPos <= h * 1.3) {
          lazy_image[l].setAttribute("src", data_src);
          lazy_image[l].removeAttribute("data-src");
          lazy_image[l].classList.add("loaded");
        }
      }
    } // Lazy Background


    var lazy_bg = document.querySelectorAll("[data-bg]");

    if (lazy_bg) {
      //console.log(lazy_bg);
      for (var l = 0; l < lazy_bg.length; l++) {
        var data_src = lazy_bg[l].dataset.bg;
        var viewportOffset = lazy_bg[l].getBoundingClientRect();
        var topPos = viewportOffset.top;

        if (topPos <= h * 1.3) {
          lazy_bg[l].setAttribute("style", "background-image:url(" + data_src + ")");
          lazy_bg[l].removeAttribute("data-bg");
          lazy_bg[l].classList.add("loaded");
        }
      }
    }
  }

  setTimeout(function () {
    document.querySelector("body").classList.add("loaded");
  }, 800);
  scrollDown();
  window.addEventListener("scroll", function (e) {
    scrollDown();
  });
})(jQuery);